import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import styles from './ButtonToForm.module.css'

export default function ButtonToForm() {
  return (
    <div className={styles.button}>
      <Stack spacing={2} direction="row">
        <Button variant="contained" size="small">Submit</Button>  
      </Stack>
    </div>
  );
}
