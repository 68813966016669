import styles from './Logo.module.css';
import logo from '../Logo/victorPortfolio.png';


function Logo(){
    return(
        <div className={styles.logo}>
           <img src={logo} alto="logo"/>
        </div>
    )
}

export default Logo;