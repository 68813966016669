import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import ButtonToForm from './ButtonToForm';

export default function FormPage() {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
        <TextField id="outlined-basic" variant="outlined"  label="Name" size="small"/><br></br>
        <TextField id="outlined-basic" variant="outlined"  label="E-mail" size="small"/><br></br>
        <TextField id="outlined-basic"  variant="outlined"  label="Message" multiline rows={6}/><br></br>
    </Box>
  );
}