import styles from './Contact.module.css';
import FormPage from '../components/Form';
import ButtonToForm from '../components/ButtonToForm';

function Contact(){
    return(
        <>
        <div className={styles.contact_header}>
            <h1>This is my Contact Page</h1>
        </div>
        <div className={styles.contact_p}>
            <h3>If you want to contact me, please send me an e-mail:</h3>
            <span>
                <FormPage />
                <ButtonToForm />
            </span>
        </div>
        </>
        )
}

export default Contact;