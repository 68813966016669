import styles from './Navbar.module.css';
import Logo from './Logo/Logo';
import FadeMenu from '../components/Menu'

import { Link } from 'react-router-dom';


function Navbar() {
    return (
        <div className={styles.navbar}>
            <span className={styles.navbar_logo}>
                <Logo />
            </span>
            <span className={styles.links}>
                <Link to="/Home">Home</Link>
                <Link to="/Projects">Projects</Link>
                <Link to="/Contact">Contact</Link>
            </span>
            <span className={styles.menu_burger}>
                <FadeMenu />
            </span>
        </div>
    )
}

export default Navbar;