import styles from './Projects.module.css';

import projetoOne from './projetoOne.png';
import projetoTwo from './projetoTwo.png';
import projetoThree from './projetoThree.png';

function Projects() {
    return (
        <>
            <div className={styles.projects_flex}>
                <div>
                    <h1>This is my Project page</h1>
                </div>
            </div>
            <div className={styles.projects_boxes}>
                <div><img src={projetoOne} /></div>
                <div><img src={projetoTwo} /></div>
                <div><img src={projetoThree} /></div>
                <div><img src={projetoOne} /></div>
                <div><img src={projetoTwo} /></div>
                <div><img src={projetoThree} /></div>
            </div>
        </>
    )
}

export default Projects;